<template>
  <div>
    <edit-block :state="state" :title="title"  :AuditStatus="AuditStatus" >
      <!-- 未填写按钮 -->
      <div slot="opt">
        <span @click="updateState(1)" class="text-primary cursor-pointer"
          >添加</span
        >
      </div>
      <!-- 填写中 -->
      <ScaleEdit
        @cancel="cancel"
        @save="finish"
        :model="model"
        slot="editing"
        :options="options"
      />
      <!-- 已填写 -->
      <ScaleDisplay :options="options" :model="model" slot="display" />
      <!-- 已填写按钮 -->
      <div class="display-opt" slot="display-opt">
        <a-space @click="updateState(1)" class="cursor-pointer text-primary">
          <img src="@/assets/icons/modify.svg" alt="" />
          <span>修改</span>
        </a-space>
      </div>
    </edit-block>
  </div>
</template>

<script>
import ScaleEdit from "@/components/user/scale/editing.vue";
import ScaleDisplay from "@/components/user/scale/display.vue";

export default {
  components: { ScaleDisplay, ScaleEdit },
  props: {
    dataSource: {
      type: Object,
      default: () => ({}),
    },
    AuditStatus: Number,
    save: Function,
  },
  data() {
    return {
      title: "企业规模与融资",
      model: {},
      state: 0,
    };
  },

  methods: {
    transform({ Scale, Listed, Resercher, Revenue, Financing, Valuation }) {
      const temp = {
        Scale,
        Financing,
        Listed,
        Resercher,
      };
      temp.Listed = Listed === undefined ? undefined : Listed ? 1 : 2;
      temp.Valuation = Valuation || Valuation === 0 ? Valuation / 10000 : null;
      temp.Revenue = Revenue || Revenue === 0 ? Revenue / 10000 : null;

      this.updateState(this.getState(temp));

      return temp;
    },
    getState({
      Scale,
      Listed,
      Resercher,
      LastYearRevenue,
      Financing,
      Valuation,
    }) {
      if (
        Scale ||
        Listed ||
        Resercher ||
        LastYearRevenue ||
        Financing ||
        Valuation
      ) {
        return 2;
      }
      return 0;
    },
    restore(obj) {
      const temp = { ...obj };
      temp.Listed = temp.Listed === 1;
      temp.Valuation = parseInt(temp.Valuation * 10000);
      temp.Revenue = parseInt(temp.Revenue * 10000);

      if (temp.ScaleId) {
        temp.Scale = {
          ID: temp.ScaleId,
          Name: this.options.scale[temp.ScaleId - 1].Name,
        };
      }

      if (temp.FinancingId) {
        temp.Financing = {
          ID: temp.FinancingId,
          Name: this.options.turn[temp.FinancingId - 1].Name,
        };
      }
      return temp;
    },
    validate() {
      this.$emit("update:status", false);
    },
    finish(data) {
      const Finance = this.restore(data);
      this.save({ Finance }).then(() => {
        this.$message.success("保存成功");
        this.model = this.transform(Finance);
      });
    },
    cancel() {
      this.updateState(this.getState(this.model));
    },
    updateState(state) {
      switch (state) {
        case 0:
          this.title = "企业规模与融资";
          break;
        case 1:
          if (this.state === 0) {
            this.title = "添加企业规模与融资";
          } else if (this.state === 2) {
            this.title = "修改企业规模与融资";
          }
          break;
        case 2:
          this.title = "企业规模与融资";
          break;
      }
      this.state = state;
    },
  },
  inject: ["options"],
  watch: {
    dataSource: {
      handler(val) {
        const temp = val && val.Finance ? val.Finance : {};
        this.model = this.transform(temp);
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>