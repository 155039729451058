<template>
  <div>
    <edit-block :state="state" :title="title"  :AuditStatus="AuditStatus" >
      <!-- 未填写按钮 -->
      <div slot="opt">
        <span @click="updateState(1)" class="text-primary cursor-pointer"
          >添加</span
        >
      </div>
      <!-- 填写中 -->
      <OtherEdit
        @cancel="cancel"
        @save="finish"
        :rules="rules"
        :model="model"
        slot="editing"
      />
      <!-- 已填写 -->
      <OtherDisplay :model="model" slot="display" />
      <!-- 已填写按钮 -->
      <div class="display-opt" slot="display-opt">
        <a-space @click="updateState(1)" class="cursor-pointer text-primary">
          <img src="@/assets/icons/modify.svg" alt="" />
          <span>修改</span>
        </a-space>
      </div>
    </edit-block>
  </div>
</template>

<script>
import OtherEdit from "@/components/user/other/editing.vue";
import OtherDisplay from "@/components/user/other/display.vue";

export default {
  components: { OtherDisplay, OtherEdit },
  props: {
    dataSource: {
      type: Object,
      default: () => ({}),
    },
    AuditStatus:Number,
    save: Function,
  },
  data() {
    return {
      title: "其他",
      model: {},
      rules: {},
      state: 0,
    };
  },
  methods: {
    transform({ Attachments, Proposal, JoinCommission }) {
      const temp = { Attachments, Proposal, JoinCommission };

      temp.JoinCommission =
        temp.JoinCommission === undefined
          ? undefined
          : temp.JoinCommission
          ? 1
          : 2;
      this.updateState(this.getState(temp));
      return temp;
    },
    getState({ Attachments, Proposal, JoinCommission }) {
      if ((Attachments && Attachments.length) || Proposal || JoinCommission) {
        return 2;
      }
      return 0;
    },
    restore(obj) {
      const temp = { ...obj };

      temp.JoinCommission = temp.JoinCommission === 1;
      return temp;
    },
    validate() {
      this.$emit("update:status", false);
    },
    finish(data) {
      const Misc = this.restore(data);
      this.save({ Misc }).then(() => {
        this.$message.success("保存成功");
        this.model = this.transform(Misc);
      });
    },
    cancel() {
      this.updateState(this.getState(this.model));
    },
    updateState(state) {
      this.state = state;
      switch (state) {
        case 0:
          this.title = "其他";
          break;
        case 1:
          if (this.state === 0) {
            this.title = "添加其他";
          } else if (this.state === 2) {
            this.title = "修改其他";
          }
          break;
        case 2:
          this.title = "其他";
          break;
      }
    },
  },
  watch: {
    dataSource: {
      handler(val) {
        const temp = val && val.Misc ? val.Misc : {};
        this.model = this.transform(temp);
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>