<template>
  <div>
    <transition>
      <div
        v-if="tipVisible"
        class="h-40 mb-20 px-10 text-sm flex justify-between items-center"
        style="
          background-color: #f4faff;
          border: 1px solid RGBA(46, 124, 237, 0.5);
        "
      >
        <span
          >请完善每个板块信息后再提交（其中工商信息、企业规模与融资，平台运营负责人信息将不会对外公开，请放心填写）</span
        >
        <!-- <a-icon @click="close" type="close" /> -->
      </div>
    </transition>
    <div class="p-20 bg-white">
      <a-space class="w-full" :size="20" direction="vertical">
        <Business
          :AuditStatus="AuditStatus"
          :dataSource="origin"
          :status.sync="status.business"
          :save="saveRecoginze"
          :isMember.sync="isMember"
          :id="ID"
          :public="Public"
        />
        <Scale
          :AuditStatus="AuditStatus"
          :dataSource="origin"
          :save="saveRecoginze"
        />
        <Operating
          :AuditStatus="AuditStatus"
          :dataSource="origin"
          :status.sync="status.Operating"
          :save="saveRecoginze"
          :isAssociation.sync="isAssociation"
          :isMarket.sync="isMarket"
          :isMember="isMember"
        />
        <Contact
          :AuditStatus="AuditStatus"
          :dataSource="origin"
          :save="saveRecoginze"
        />
        <Field
          :AuditStatus="AuditStatus"
          :dataSource="origin"
          :status.sync="status.field"
          :save="saveRecoginze"
        />

        <Talent
          :AuditStatus="AuditStatus"
          :dataSource="origin"
          :status.sync="status.Talent"
          :save="saveRecoginze"
        />
        <Resource
          :AuditStatus="AuditStatus"
          :dataSource="origin"
          :save="saveRecoginze"
          v-if="isMember"
        />
        <!-- <Other
        :AuditStatus="AuditStatus"
        :dataSource="origin"
        :save="saveRecoginze"
      /> -->

        <Association
          :AuditStatus="AuditStatus"
          :dataSource="origin"
          :save="saveRecoginze"
          v-if="isMember && isAssociation"
        />
        <Market
          :AuditStatus="AuditStatus"
          :dataSource="origin"
          :save="saveRecoginze"
          v-if="isMember && isMarket"
        />
        <div class="text-right">
          <a-button
            v-if="isManager && AuditStatus === 1"
            size="large"
            type="primary"
            @click="modal = true"
          >
            审核
          </a-button>
          <a-button
            v-else-if="!isManager && AuditStatus === 0"
            size="large"
            type="primary"
            @click="submitRecoginze"
            >提交</a-button
          >
        </div>
      </a-space>
      <a-modal
        v-model="modal"
        title="审核"
        okText="审核"
        cancelText="取消"
        @ok="evaluateRecoginze"
      >
        <a-form-model :model="evaluate">
          <a-form-model-item>
            <a-select v-model="evaluate.AuditStatus">
              <a-select-option :value="2">审核通过</a-select-option>
              <a-select-option :value="3">审核失败</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item v-if="evaluate.AuditStatus === 3">
            <gf-textarea
              :required="true"
              prop="Feedback"
              :max="300"
              :rows="5"
              v-model="evaluate.Feedback"
            ></gf-textarea>
          </a-form-model-item>
        </a-form-model>
      </a-modal>
    </div>
  </div>
</template>

<script>
import Business from "./Business.vue";
import Contact from "./Contact.vue";
import Field from "./Field.vue";
import Scale from "./Scale.vue";
import Talent from "./Talent.vue";
import Resource from "./Resource.vue";
import Other from "./Other.vue";
import Operating from "./Operating.vue";
import Association from "./Association.vue";
import Market from "./Market.vue";

import { saveCompanyRecoginze, getCompanyRecoginze } from "@/api";
import { updateProCompany, createCompany } from "@/api/company.js";
import {
  getProCompany,
  submitCompany,
  evaluateProCompany,
} from "@/api/company.js";

export default {
  components: {
    Business,
    Contact,
    Field,
    Scale,
    Talent,
    Resource,
    Other,
    Operating,
    Association,
    Market,
  },
  data() {
    return {
      tipVisible: true,
      status: {
        business: false,
        field: false,
      },
      evaluate: {
        AuditStatus: 2,
        Feedback: "",
      },
      modal: false,
      origin: {},
      AuditStatus: 0,
      isMember: true,
      isAssociation: true,
      isMarket: true,
      ID: "",
      Public: false,
    };
  },
  computed: {
    role() {
      return this.$store.state.role;
    },
    isManager() {
      return this.role === 6 || this.role === 7;
    },
    info() {
      return this.$store.state.user.USER_INFO;
    },
  },
  created() {
    const id = this.$route.params.id;
    this.id = id;
    if (id && this.isManager) {
      this.getProCompany(id);
    } else {
      this.getRecoginze();
    }
  },
  methods: {
    close() {
      this.tipVisible = false;
    },
    getProCompany(id) {
      return getProCompany(id).then(({ data }) => {
        this.AuditStatus = data.AuditStatus;
        this.ID = data.ID;
        this.Public = data.Public;
        this.origin = data.Detail || {};
      });
    },
    getRecoginze() {
      if (this.isManager) {
        this.getProCompany(this.ID);
      } else {
        getCompanyRecoginze().then(({ data }) => {
          if (data.ID) {
            this.AuditStatus = data.AuditStatus;
            this.ID = data.ID;
            this.Public = data.Public;
            this.origin = data.Detail || {};
          } else {
            createCompany().then(({ data }) => {
              this.AuditStatus = data.AuditStatus;
              this.ID = data.ID;
              this.Public = data.Public;
              this.origin = data.Detail || {};
            });
          }
        });
      }
    },
    saveRecoginze(data) {
      if (this.isManager) {
        return updateProCompany(this.ID, data).then(() => {
          this.$store.dispatch("getUserInfo");
          this.getRecoginze();
          return Promise.resolve();
        });
      } else {
        return saveCompanyRecoginze(data).then(() => {
          this.$store.dispatch("getUserInfo");
          this.getRecoginze();
          return Promise.resolve();
        });
      }
    },
    submitRecoginze() {
      let status = true;
      for (let key in this.status) {
        if (!this.status[key]) {
          status = false;
        }
      }
      if (status) {
        submitCompany().then(() => {
          this.$message.success("提交审核");
          this.getRecoginze();
        });
      } else {
        this.$message.error("资料填写不完整");
      }
    },
    evaluateRecoginze() {
      evaluateProCompany(this.id, this.evaluate).then(() => {
        this.$message.success("提交成功");
        this.modal = false;
        this.getProCompany(this.id);
      });
    },
    validate() {},
  },
};
</script>