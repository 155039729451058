<template>
  <div>
    <edit-block
      :state="state"
      :status="status"
      :title="title"
      :AuditStatus="AuditStatus"
    >
      <!-- 未填写按钮 -->
      <div slot="opt">
        <span @click="updateState(1)" class="text-primary cursor-pointer"
          >添加</span
        >
      </div>
      <!-- 填写中 -->
      <FieldEdit
        @cancel="cancel"
        @save="finish"
        :model="model"
        slot="editing"
        :options="options"
      />
      <!-- 已填写 -->
      <FieldDisplay :options="options" :model="model" slot="display" />
      <!-- 已填写按钮 -->
      <div class="display-opt" slot="display-opt">
        <a-space @click="updateState(1)" class="cursor-pointer text-primary">
          <img src="@/assets/icons/modify.svg" alt="" />
          <span>修改</span>
        </a-space>
      </div>
    </edit-block>
  </div>
</template>

<script>
import FieldEdit from "@/components/user/field/editing.vue";
import FieldDisplay from "@/components/user/field/display.vue";

export default {
  components: { FieldDisplay, FieldEdit },
  props: {
    dataSource: {
      type: Object,
      default: () => ({}),
    },
    AuditStatus: Number,
    status: Boolean,
    save: Function,
  },
  data() {
    return {
      title: "行业领域情况",
      model: {},
      state: 0,
    };
  },
  inject: ["options"],
  methods: {
    transform(data) {
      const temp = {};

      temp.Industries = data.Industries || [];
      temp.Fields = data.Fields || [];
      temp.Tags = data.Tags || [];

      this.updateState(this.getState(temp));

      this.validate(temp);

      return temp;
    },
    getState({ Industries, Fields, Tags }) {
      if (Industries.length || Fields.length || Tags.length) {
        return 2;
      }

      return 0;
    },
    restore(obj) {
      const temp = { ...obj };
      temp.Industries = [];
      temp.IndustryIDs.forEach((item) => {
        if (item > 0) {
          temp.Industries.push({
            ID: item,
            Name: this.options.industry[item - 1].Name,
          });
        }
      });
      if (temp.IndustryIDs.includes(-1)) {
        temp.Industry.forEach((item) => {
          temp.Industries.push({
            ID: 0,
            Name: item,
          });
        });
      }

      temp.Fields = [];
      temp.FieldIDs.forEach((item) => {
        if (item > 0) {
          temp.Fields.push({
            ID: item,
            Name: this.options.field[item - 1].Name,
          });
        }
      });
      if (temp.FieldIDs.includes(-1)) {
        temp.Field.forEach((item) => {
          temp.Fields.push({
            ID: 0,
            Name: item,
          });
        });
      }

      temp.Tags = [];
      temp.TagIDs.forEach((item) => {
        if (item > 0) {
          temp.Tags.push({
            ID: item,
            Name: this.options.tag[item - 1].Name,
          });
        }
      });
      if (temp.TagIDs.includes(-1)) {
        temp.Tag.forEach((item) => {
          temp.Tags.push({
            ID: 0,
            Name: item,
          });
        });
      }

      return temp;
    },
    validate({ Industries, Fields, Tags }) {
      let status = true;
      if (!Industries.length || !Fields.length || !Tags.length) {
        status = false;
      }
      this.$emit("update:status", status);
    },
    finish(data) {
      const Label = this.restore(data);
      this.save({ Label }).then(() => {
        this.$message.success("保存成功");
        this.model = this.transform(Label);
      });
    },
    cancel() {
      this.updateState(this.getState(this.model));
    },
    updateState(state) {
      switch (state) {
        case 0:
          this.title = "行业领域情况";
          break;
        case 1:
          if (this.state === 0) {
            this.title = "添加行业领域情况";
          } else if (this.state === 2) {
            this.title = "修改行业领域情况";
          }
          break;
        case 2:
          this.title = "行业领域情况";
          break;
      }
      this.state = state;
    },
  },
  watch: {
    dataSource: {
      handler(val) {
        const temp = val && val.Label ? val.Label : {};
        this.model = this.transform(temp);
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>