<template>
  <div>
    <edit-block
      :state="state"
      :status="status"
      :title="title"
      :AuditStatus="AuditStatus"
      :showStatus="true"
    >
      <!-- 未填写按钮 -->
      <div slot="opt">
        <span @click="updateState(1)" class="text-primary cursor-pointer"
          >添加</span
        >
      </div>
      <!-- 填写中 -->
      <BusinessEdit
        @cancel="cancel"
        @save="finish"
        :model="model"
        slot="editing"
        :options="options"
      />
      <!-- 已填写 -->
      <BusinessDisplay :options="options" :model="model" slot="display" />
      <!-- 已填写按钮 -->
      <div slot="display-opt">
        <a-space class="cursor-pointer text-primary">
          <div class="display-opt" @click="updateState(1)">
            <a-space>
              <img src="@/assets/icons/modify.svg" alt="" />
              <span>修改</span>
            </a-space>
          </div>

          <!-- <a-select v-if="!$store.getters.isManager" style="width: 250px" @change="changeVisible" v-model="model.visible"> -->
          <!-- <a-select-option :value="0">
              对所有企业都不可见
            </a-select-option> -->
          <!-- <a-select-option :value="1">
              全公开
            </a-select-option>
            <a-select-option :value="2">
              向星球伙伴公开
            </a-select-option> -->
          <!-- </a-select> -->
        </a-space>
      </div>
    </edit-block>
  </div>
</template>

<script>
import BusinessEdit from "@/components/user/business/editing.vue";
import BusinessDisplay from "@/components/user/business/display.vue";
import { setCompanyVisible } from "@/api/company.js";

export default {
  components: { BusinessDisplay, BusinessEdit },
  props: {
    dataSource: {
      type: Object,
      default: () => ({}),
    },
    AuditStatus: Number,
    status: Boolean,
    save: Function,
    defaultState: Number,
    id: String,
    public: Boolean,
  },
  data() {
    return {
      title: "工商信息",
      model: {},
      state: 0, // 0 未填写 1 填写中 2 已填写
    };
  },
  methods: {
    transform({
      Name,
      EName,
      ShortName,
      MembershipTypeId,
      CreditCode,
      Representative,
      RegisterCapital,
      RegisterTime,
      RegisterAddress,
      BusinessAddress,
      Property,
      License,
      Logo,
      id,
      Referrer,
      ReferrerCompany,
      visible,
    }) {
      const temp = {
        Name,
        EName,
        ShortName,
        MembershipTypeId,
        CreditCode,
        Representative,
        RegisterTime,
        RegisterAddress,
        BusinessAddress,
        Property,
        License,
        id,
        Referrer,
        ReferrerCompany,
        visible,
      };

      this.updateState(this.getState(temp));
      temp.Logo = Logo || {};
      temp.License = License || {};
      temp.Property = Property || {};
      temp.RegisterCapital =
        RegisterCapital || RegisterCapital === 0
          ? RegisterCapital / 10000
          : null;
      this.$emit(
        "update:isMember",
        !!MembershipTypeId && MembershipTypeId.ID !== 0
      );

      this.validate(temp);

      return temp;
    },
    getState({
      Name,
      EName,
      ShortName,
      MembershipTypeId,
      CreditCode,
      Representative,
      RegisterCapital,
      RegisterTime,
      RegisterAddress,
      BusinessAddress,
    }) {
      if (
        Name ||
        EName ||
        ShortName ||
        CreditCode ||
        Representative ||
        RegisterTime ||
        MembershipTypeId ||
        RegisterAddress ||
        BusinessAddress ||
        RegisterCapital
      ) {
        return 2;
      }
      return 0;
    },
    restore(obj) {
      const temp = { ...obj };
      temp.RegisterCapital = parseInt(temp.RegisterCapital * 10000);

      temp.RegisterTime = +temp.RegisterTime;

      if (temp.CategoryId === -1) {
        temp.Property = {
          ID: 0,
          Name: temp.Category,
        };
      } else {
        temp.Property = {
          ID: temp.CategoryId,
          Name: this.options.nature[temp.CategoryId - 1].Name,
        };
      }
      temp.MembershipTypeId = {
        ID: temp.MembershipTypeId,
        Name: this.options.membership[temp.MembershipTypeId].Name,
      };

      return temp;
    },
    validate({
      Name,
      MembershipTypeId,
      CreditCode,
      RegisterAddress,
      BusinessAddress,
      Property,
      Logo,
    }) {
      let status = true;
      if (!Name || !CreditCode || !Property || !Logo) {
        status = false;
      }
      if (this.options.nature.length === Property && !Property) {
        status = false;
      }
      if (
        MembershipTypeId &&
        MembershipTypeId.ID &&
        (!RegisterAddress || !BusinessAddress)
      ) {
        status = false;
      }
      this.$emit("update:status", status);
    },
    finish(data) {
      const Info = this.restore(data);
      this.save({
        Info,
        Membership: {
          Referrer: Info.Referrer,
          ReferrerCompany: Info.ReferrerCompany,
          Type: Info.MembershipTypeId,
        },
      }).then(() => {
        this.$message.success("保存成功");
        this.model = this.transform(Info);
      });
    },
    cancel() {
      this.updateState(this.getState(this.model));
    },
    updateState(state) {
      switch (state) {
        case 0:
          this.title = "工商信息";
          break;
        case 1:
          if (this.state === 0) {
            this.title = "添加工商信息";
          } else if (this.state === 2) {
            this.title = "修改工商信息";
          }
          break;
        case 2:
          this.title = "工商信息";
          break;
      }
      this.state = state;
    },
    changeVisible(e) {
      let temp = {};
      if (e === 1) {
        temp = {
          Public: true,
          Visible: true,
        };
      } else if (e === 2) {
        temp = {
          Public: false,
          Visible: true,
        };
      } else {
        temp = {
          Public: false,
          Visible: false,
        };
      }
      setCompanyVisible(temp).then(() => {
        this.$message.success("设置可见性成功");
      });
    },
  },
  inject: ["options"],
  watch: {
    dataSource: {
      handler(val) {
        const temp = val && val.Info ? val.Info : {};
        if (val.Membership) {
          temp.MembershipTypeId = val.Membership.Type || null;
          temp.Referrer = val.Membership.Referrer;
          temp.ReferrerCompany = val.Membership.ReferrerCompany;
        }
        temp.id = this.id;
        temp.visible = this.public ? 1 : 2;
        this.model = this.transform(temp);
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>