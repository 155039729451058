<template>
  <div>
    <edit-block tip="经企业授权，以企业名义，与协会进行日常工作对接的企业工作人员" :state="state" :title="title" :AuditStatus="AuditStatus">
      <!-- 未填写按钮 -->
      <div slot="opt">
        <span @click="updateState(1)" class="text-primary cursor-pointer"
          >添加</span
        >
      </div>
      <!-- 填写中 -->
      <AssociationEdit
        @cancel="cancel"
        @save="finish"
        :options="options"
        :model="model"
        slot="editing"
      />
      <!-- 已填写 -->
      <AssociationDisplay :options="options" :model="model" slot="display" />
      <!-- 已填写按钮 -->
      <div class="display-opt" slot="display-opt">
        <a-space @click="updateState(1)" class="cursor-pointer text-primary">
          <img src="@/assets/icons/modify.svg" alt="" />
          <span>修改</span>
        </a-space>
      </div>
    </edit-block>
  </div>
</template>

<script>
import AssociationEdit from "@/components/user/association/editing.vue";
import AssociationDisplay from "@/components/user/association/display.vue";

export default {
  components: { AssociationDisplay, AssociationEdit },
  props: {
    dataSource: {
      type: Object,
      default: () => ({}),
    },
    AuditStatus: Number,
    save: Function,
  },
  data() {
    return {
      title: "协会对接人",
      model: {},
      state: 0,
    };
  },
  methods: {
    transform({
      Name,
      Department,
      Email,
      Identity,
      Phone,
      Position,
      Telephone,
      Wechat,
      IDBack,
      IDFront,
    }) {
      const temp = {
        Name,
        Department,
        Email,
        Identity,
        Phone,
        Position,
        Telephone,
        Wechat,
        IDBack,
        IDFront,
      };

      this.updateState(this.getState(temp));

      this.validate(temp);
      return temp;
    },
    getState({
      Name,
      Department,
      Email,
      Identity,
      Phone,
      Position,
      Telephone,
      Wechat,
      IDFront,
      IDBack,
    }) {
      if (
        Name ||
        Department ||
        Email ||
        Identity ||
        Phone ||
        Position ||
        Telephone ||
        Wechat ||
        IDFront ||
        IDBack
      ) {
        return 2;
      }
      return 0;
    },
    restore(obj) {
      const temp = { ...obj };

      return temp;
    },
    validate() {},
    finish(data) {
      const ContactStaff = this.restore(data);
      this.save({ ContactStaff }).then(() => {
        this.$message.success("保存成功");
        this.model = this.transform(ContactStaff);
      });
    },
    cancel() {
      this.updateState(this.getState(this.model));
    },
    updateState(state) {
      switch (state) {
        case 0:
          this.title = "协会对接人";
          break;
        case 1:
          if (this.state === 0) {
            this.title = "添加协会对接人";
          } else if (this.state === 2) {
            this.title = "修改协会对接人";
          }
          break;
        case 2:
          this.title = "协会对接人";
          break;
      }
      this.state = state;
    },
  },
  inject: ["options"],
  watch: {
    dataSource: {
      handler(val) {
        const temp = val && val.ContactStaff ? val.ContactStaff : {};
        this.model = this.transform(temp);
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>