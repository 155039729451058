<template>
  <div>
    <edit-block
      tip="经企业授权，以企业名义，申请本生态平台账号（公司全名）认证服务，并负责该帐号内容维护及运营管理的企业工作人员"
      :state="state"
      :status="status"
      :title="title"
      :AuditStatus="AuditStatus"
    >
      <!-- 未填写按钮 -->
      <div slot="opt">
        <span @click="updateState(1)" class="text-primary cursor-pointer"
          >添加</span
        >
      </div>
      <!-- 填写中 -->
      <OperatingEdit
        @cancel="cancel"
        @save="finish"
        :isMember="isMember"
        :model="model"
        :options="options"
        slot="editing"
      />
      <!-- 已填写 -->
      <OperatingDisplay :options="options" :model="model" slot="display" />
      <!-- 已填写按钮 -->
      <div class="display-opt" slot="display-opt">
        <a-space @click="updateState(1)" class="cursor-pointer text-primary">
          <img src="@/assets/icons/modify.svg" alt="" />
          <span>修改</span>
        </a-space>
      </div>
    </edit-block>
  </div>
</template>

<script>
import OperatingEdit from "@/components/user/operating/editing.vue";
import OperatingDisplay from "@/components/user/operating/display.vue";

export default {
  components: { OperatingDisplay, OperatingEdit },
  props: {
    dataSource: {
      type: Object,
      default: () => ({}),
    },
    AuditStatus: Number,
    status: Boolean,
    save: Function,
    isMember: Boolean,
  },
  data() {
    return {
      title: "平台运营负责人",
      model: {},
      state: 0,
    };
  },
  methods: {
    transform({
      Name,
      Department,
      Email,
      Identity,
      Phone,

      Position,
      Telephone,
      Wechat,
      Missive,
      IDBack,
      IDFront,
      ContactStaffOption,
      MarketStaffOption,
    }) {
      const temp = {
        Name,
        Department,
        Email,
        Identity,
        Phone,
        Position,
        Telephone,
        Wechat,
        Missive,
        IDBack,
        IDFront,
        ContactStaffOption,
        MarketStaffOption,
      };

      this.$emit("update:isAssociation", !ContactStaffOption);
      this.$emit("update:isMarket", !MarketStaffOption);

      this.updateState(this.getState(temp));

      this.validate(temp);
      return temp;
    },
    getState({
      Name,
      Department,
      Email,
      Identity,
      Phone,
      Position,
      Telephone,
      Wechat,
      IDFront,
      IDBack,
      Missive,
    }) {
      if (
        Name ||
        Department ||
        Email ||
        Identity ||
        Phone ||
        Position ||
        Telephone ||
        Wechat ||
        IDFront ||
        IDBack ||
        Missive
      ) {
        return 2;
      }
      return 0;
    },
    restore(obj) {
      const temp = { ...obj };

      temp.Missive = temp.Missive[0] || {};

      return temp;
    },
    validate({ Name, Department, Email, Phone, Position, Wechat }) {
      let status = true;
      if (!Name || !Department || !Email || !Phone || !Position || !Wechat) {
        status = false;
      }
      this.$emit("update:status", status);
    },
    finish(data) {
      const Operator = this.restore(data);
      this.save({
        Operator,
        ContactStaffOption: Operator.ContactStaffOption,
        MarketStaffOption: Operator.MarketStaffOption,
      }).then(() => {
        this.$message.success("保存成功");
        this.model = this.transform(Operator);
      });
    },
    cancel() {
      this.updateState(this.getState(this.model));
    },
    updateState(state) {
      switch (state) {
        case 0:
          this.title = "平台运营负责人";
          break;
        case 1:
          if (this.state === 0) {
            this.title = "添加平台运营负责人";
          } else if (this.state === 2) {
            this.title = "修改平台运营负责人";
          }
          break;
        case 2:
          this.title = "平台运营负责人";
          break;
      }
      this.state = state;
    },
  },
  inject: ["options"],
  watch: {
    dataSource: {
      handler(val) {
        const temp = val && val.Operator ? val.Operator : {};
        temp.ContactStaffOption = val.ContactStaffOption;
        temp.MarketStaffOption = val.MarketStaffOption;
        this.model = this.transform(temp);
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>