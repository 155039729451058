<template>
  <div>
    <edit-block :state="state" :title="title"  :AuditStatus="AuditStatus" >
      <!-- 未填写按钮 -->
      <div slot="opt">
        <span @click="updateState(1)" class="text-primary cursor-pointer"
          >添加</span
        >
      </div>
      <!-- 填写中 -->
      <MarketEdit
        @cancel="cancel"
        @save="finish"
        :model="model"
        :options="options"
        slot="editing"
      />
      <!-- 已填写 -->
      <MarketDisplay :options="options" :model="model" slot="display" />
      <!-- 已填写按钮 -->
      <div class="display-opt" slot="display-opt">
        <a-space @click="updateState(1)" class="cursor-pointer text-primary">
          <img src="@/assets/icons/modify.svg" alt="" />
          <span>修改</span>
        </a-space>
      </div>
    </edit-block>
  </div>
</template>

<script>
import MarketEdit from "@/components/user/market/editing.vue";
import MarketDisplay from "@/components/user/market/display.vue";

export default {
  components: { MarketDisplay, MarketEdit },
  props: {
    dataSource: {
      type: Object,
      default: () => ({}),
    },
    AuditStatus:Number,
    save: Function,
  },
  data() {
    return {
      title: "市场负责人",
      model: {},
      state: 0,
    };
  },
  created() {
    // this.model = this.transform(this.dataSource);
  },
  inject: ["options"],
  methods: {
    transform({
      Name,
      Email,
      Phone,
      Gender,
      Position,
      Wechat,
      WechatPub,
      Website,
      Medium,
    }) {
      const temp = {
        Name,
        Email,
        Gender,
        Phone,
        Wechat,
        Position,
        WechatPub,
        Website,
        Medium,
      };
      temp.Gender = Gender;
      this.updateState(this.getState(temp));
      return temp;
    },
    getState({
      Name,
      Email,
      Phone,
      Gender,
      Position,
      Wechat,
      Medium,
      WechatPub,
      Website,
    }) {
      if (
        Name ||
        Email ||
        Gender ||
        Phone ||
        Position ||
        Wechat ||
        Medium ||
        WechatPub ||
        Website
      ) {
        return 2;
      }
      return 0;
    },
    restore(obj) {
      const temp = { ...obj };

      return temp;
    },
    validate() {
      this.$emit("update:status", false);
    },
    finish(data) {
      const MarketStaff = this.restore(data);
      this.save({ MarketStaff }).then(() => {
        this.$message.success("保存成功");
        this.model = this.transform(MarketStaff);
      });
    },
    cancel() {
      this.updateState(this.getState(this.model));
    },
    updateState(state) {
      switch (state) {
        case 0:
          this.title = "市场负责人";
          break;
        case 1:
          if (this.state === 0) {
            this.title = "添加市场负责人";
          } else if (this.state === 2) {
            this.title = "修改市场负责人";
          }
          break;
        case 2:
          this.title = "市场负责人";
          break;
      }
      this.state = state;
    },
  },
  watch: {
    dataSource: {
      handler(val) {
        const temp = val && val.MarketStaff ? val.MarketStaff : {};
        this.model = this.transform(temp);
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>